














































































.p-login-personal {
  height: 608px;
  min-width: 1100px;
  background-size: cover;
  background-position: 50%;
  background-color: #cccccc;
  background-repeat: no-repeat;
  background-image: url("/image/login/personal/bg.jpg");
  > .body {
    width: 1100px;
    display: flex;
    margin: 22px auto;
    align-items: center;
    > .banner {
      margin-right: 48px;
    }
    > .form {
      width: 428px;
      height: 424px;
      display: flex;
      text-align: center;
      flex-direction: column;
      justify-content: center;
      position: relative;
      padding: 9px 19px 17px 9px;
      background-repeat: no-repeat;
      background-image: url("/image/login/form.png");
      &.xxx {
        &::after {
          top: 8px;
          left: 14px;
          right: 7px;
          bottom: 20px;
          z-index: 2;
          color: red;
          display: flex;
          font-size: 18px;
          position: absolute;
          border-radius: 8px;
          align-items: center;
          content: "系统维护中...";
          justify-content: center;
          background-color: #ffffff80;
          text-shadow: 0 0 20px #ffffff;
        }
      }
      > .title {
        margin: 50px;
        font-size: 24px;
        text-align: center;
      }
      > .username,
      > .password {
        display: flex;
        margin: 20px 50px;
        line-height: 30px;
        align-items: center;
        border-style: solid;
        border-color: #cccccc;
        border-width: 0px 0px 1px 0px;
        > i {
          margin: 5px;
          font-size: 18px;
          color: #666666;
          line-height: initial;
        }
        > input {
          flex: 1;
          border: none;
          outline: none;
          font-size: 16px;
          // color: #333333;
          padding: 0px 4px;
          line-height: inherit;
          background-color: transparent;
          &::placeholder {
            font-size: 14px;
            color: #999999;
          }
          &:-webkit-autofill {
            font-size: 16px !important;
            background-color: transparent !important;
            // -webkit-text-fill-color: #333333;
            transition: background-color 31536000s cubic-bezier(1, 0, 1, 0);
          }
          &[type="password"] {
            letter-spacing: 4px;
            &::placeholder {
              letter-spacing: initial;
            }
          }
        }
      }
      > .other {
        margin: 20px 50px;
        text-align: right;
        > a {
          &:not(:hover) {
            color: #666666;
          }
          &:not(:last-child) {
            margin-right: 15px;
          }
        }
      }
      > .option {
        margin: 40px 50px;
        > button {
          width: 100%;
          border: none;
          outline: none;
          cursor: pointer;
          font-size: 16px;
          color: #ffffff;
          line-height: 34px;
          border-radius: 4px;
          background-color: #41ac76;
          > .spliter {
            margin: 0px 10px;
          }
        }
      }
    }
  }
}
